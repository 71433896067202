import JoinForm from "components/join-form/JoinForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Join = () => {
  return (
    <div>
      <div className="py-8"></div>
      <div className="container mx-auto">
        <div className="text-dark">
          <h1 className="text-xl font-semibold mb-4">
            Beitrittserklärung zum Bündnis Demokratie stärken
            Rielasingen-Worblingen-Arlen
          </h1>
          <p className="text-l">
            <p className="mb-3">
              {" "}
              Sie können dem Bündniss hier online beitreten oder alternativ das
              Beitrittsformular{" "}
              <a
                className="text-gray-600 hover:text-blue-500 transition-all"
                href={
                  process.env.PUBLIC_URL +
                  "/assets/documents/Buendnis_Beitritt.pdf"
                }
                download="Buendnis_Beitritt.pdf"
              >
                hier herunterladen ↓
              </a>{" "}
              .{" "}
            </p>
            <p className="mb-3">
              <JoinForm showToast={toast}></JoinForm>
            </p>
          </p>
        </div>
      </div>
      <div className="py-8"></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="colored"
      />
    </div>
  );
};

export default Join;
