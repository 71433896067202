import axios from "axios";
import { useEffect, useState } from "react";
const emptyForm = {
  name: "",
  date: new Date().toISOString().split("T")[0],
  mailAdress: "",
  location: "",
  namePublicly: true,
  readDocument: false,
};

const validateEmail = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

const JoinForm = ({ showToast }) => {
  const [formInput, setFormInput] = useState(emptyForm);

  const [formCanBeSend, setFormCanBeSent] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const [stayInformed, setStayInformed] = useState(false);

  const generateEmail = (event, form, canBeSend) => {
    event.preventDefault();
    console.log(validateEmail(form.mailAdress));

    if (stayInformed && !validateEmail(form.mailAdress)) {
      showToast("Bitte prüfen Sie ihre angegebene E-Mailadresse");
      return;
    }
    if (canBeSend) {
      setWaitingForResponse(true);
      axios
        .post(
          "https://w1brll5nh7.execute-api.eu-central-1.amazonaws.com/default/sendSignUpMail",
          form,
          {
            headers: {
              "Content-Type": "text/plain",
            },
            withCredentials: false,
          }
        )
        .then(function (response) {
          console.log(response);
          setFormInput(emptyForm);
          let successMessage = "Vielen Dank für Ihr Anmeldung!";
          if (stayInformed) {
            successMessage += " Eine Bestätigungmail wurde verschickt.";
          }
          showToast.success(successMessage);
        })
        .catch(function (error) {
          console.log(error);
          showToast.error(
            "Leider konnte die Anmeldung nicht abgeschlossen werden! Bitte wenden Sie sich an demokratie.rwa@web.de"
          );
        })
        .finally(() => {
          setWaitingForResponse(false);
        });
    }
  };

  useEffect(() => {
    setFormCanBeSent(
      !!formInput.name &&
        !!formInput.location &&
        formInput.readDocument &&
        stayInformed === !!formInput.mailAdress
    );
  }, [formInput, stayInformed]);

  return (
    <form>
      <div>
        <p>
          Zu den Werten und Zielen des BÜNDNISSES DEMOKRATIE STÄRKEN (die Sie{" "}
          <a href="www.demokratie-rwa.de">hier</a> finden) bekenne ich mich und
          trete dem Bündnis hiermit bei.
        </p>
        <p className="mb-3">
          <p className="mb-1">
            * Name, Vorname:{" "}
            <input
              type="text"
              value={formInput.name}
              onInput={(e) =>
                setFormInput((form) => ({ ...form, name: e.target.value }))
              }
            ></input>
          </p>
          <p className="mb-1">
            * Ort:{" "}
            <input
              type="text"
              value={formInput.location}
              onInput={(e) =>
                setFormInput((form) => ({ ...form, location: e.target.value }))
              }
            ></input>
          </p>
          <p className="mb-1">
            * Datum:{" "}
            <input
              type="date"
              readOnly={true}
              value={formInput.date}
              onInput={(e) =>
                setFormInput((form) => ({ ...form, date: e.target.value }))
              }
            ></input>
          </p>
        </p>
        <p className="mb-3">
          Die Regeln, die sich das Bündnis für seine interne Zusammenarbeit
          gegeben hat, sind{" "}
          <a
            className="text-gray-600 hover:text-blue-500 transition-all"
            href={
              process.env.PUBLIC_URL +
              " /assets/documents/Buendnis_Interne_Regeln.pdf"
            }
            download="Buendnis_Beitritt.pdf"
          >
            hier abrufbar.
          </a>
        </p>
        <p className="mb-3">
          <input
            type="checkbox"
            checked={stayInformed}
            onClick={(e) => setStayInformed((stayInformed) => !stayInformed)}
          ></input>{" "}
          Ich möchte über Veranstaltungen, Aktionen und Zusammenkünfte des
          BÜNDNISSES DEMOKRATIE STÄRKEN informiert werden und gebe dafür
          nachfolgend meine E-Mailadresse an. Ich bin einverstanden, dass meine
          E-Mailadresse zu diesem Zweck gespeichert und verwendet wird. Wenn Sie
          dies nicht wollen, füllen sie das Feld E-Mailadresse bitte NICHT aus.
        </p>
        <p className="mb-3">
          <p className="mb-1">
            E-Mailadresse:{" "}
            <input
              type="email"
              disabled={!stayInformed}
              value={formInput.mailAdress}
              onInput={(e) =>
                setFormInput((form) => ({
                  ...form,
                  mailAdress: e.target.value,
                }))
              }
            ></input>
          </p>
        </p>
        <p className="mb-3">
          Ich bin
          <p className="mb-3">
            <p className="mb-1">
              <input
                type="radio"
                id="agree"
                name="name-publicly"
                checked={formInput.namePublicly}
                onClick={(e) =>
                  setFormInput((form) => ({ ...form, namePublicly: true }))
                }
              ></input>
              <label for="agree"> einverstanden</label>
            </p>
            <p className="mb-1">
              <input
                type="radio"
                id="disagree"
                name="name-publicly"
                checked={!formInput.namePublicly}
                onClick={(e) =>
                  setFormInput((form) => ({ ...form, namePublicly: false }))
                }
              ></input>
              <label for="disagree"> NICHT einverstanden</label>
            </p>
          </p>
          dass mein Name als Unterstützer des BÜNDNISSES DEMOKRATIE STÄRKEN auch
          öffentlich genannt werden darf, z.B. Dritten, gegenüber der Presse
          oder auf der Internetseite des Bündnisses.
        </p>
        <p className="mb-3">
          Der Beitritt, die Zustimmung zur Nutzung der E-Mailadresse sowie zur
          öffentlichen Nennung des Namens kann jederzeit ohne Angabe von Gründen
          durch eine E-Mail an demokratie.rwa@web.de oder schriftlich gegenüber
          einem Sprecher, einer Sprecherin widerrufen werden. Eine grobe
          Zuwiderhandlung gegen die Werte und Ziele des Bündnisses kann zu einem
          Ausschluss von dem Bündnis führen.
        </p>
        <p className="mb-3">
          <input
            type="checkbox"
            checked={formInput.readDocument}
            onClick={(e) =>
              setFormInput((form) => ({
                ...form,
                readDocument: !form.readDocument,
              }))
            }
          ></input>
          * Hiermit bestätige ich, dass ich das Betrittformular gelesen habe und
          meine Angaben vollständig und korrekt sind.
        </p>
        <p className="mb-3 text-red-600">
          Bitte füllen Sie die mit * gekennzeichneten Felder aus.
        </p>

        <button
          disabled={!formCanBeSend && waitingForResponse}
          onClick={(e) => generateEmail(e, formInput, formCanBeSend)}
          className={`bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded ${
            !formCanBeSend && "cursor-not-allowed opacity-50"
          }`}
        >
          {" "}
          Betritt per Mail senden{" "}
        </button>
      </div>
    </form>
  );
};

export default JoinForm;
