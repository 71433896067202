import "./AddEventModal.css";
import Modal from "react-modal";

const AddEventModal = ({ isModalOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={console.dir}
      onRequestClose={() =>
        window.confirm("Möchten Sie die Bearbeitung beenden?") && closeModal()
      }
      style={{}}
      contentLabel="Neue Veranstaltung anlegen"
    >
      <div className="flexbox-wrapper">
        <div className="flexbox-item">
          <h2>Neue Veranstaltung anlegen</h2>
          <hr />
          <br />
          <div>
            <input type="text" className="basic-text" />
            <input type="date" className="basic-text" />
            <input type="time" className="basic-text" />
          </div>
        </div>
        <div className="flexbox-item">
          <h2>Konfiguration</h2>
          <hr />
          <br />
          <pre className="code-preview">
            {`-   title: sjdls
    new line`}
          </pre>
        </div>
      </div>
    </Modal>
  );
};

export default AddEventModal;
